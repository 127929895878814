import React, {useState,useEffect,useReducer, useCallback} from 'react';
//import sanityClient from "../client.js";
import DataContext from '../components/dataContext';
import {priceFormat} from '../components/Helpers';
import config from '../components/Config';
import _ from 'lodash';

function DataProvider (props){

	const [postsAll, 	setPostsAll] 		= useState([]);
	const [posts, 		setPosts] 			= useState([]);
	const [post, 		setPost] 			= useState(null);
	const [categories, 	setCategories] 		= useState([]);
	const [category, 	setCategory] 		= useState( null );
	const [settings, 	setSettings] 		= useState( null );

	const [postsLoaded, 	setPostsLoaded]			= useState( false );
	const [loaderStatus, 	loaderSetStatus] 		= useState( false );


	// initial load + fill data provider
	useEffect( ()=>{
		// load settings once
		if( settings === null ){
			fetch( `${config.app.api}settings` )
			.then( result => result.json())
			.then( data => {
				//let data
				let objData = {}
				data.forEach(setting => {
					objData[setting.field_key] = setting.field_value;
				})
				setSettings(objData);
			})
			.catch( console.error );
		}
		// load posts once
		if( posts.length <= 0 ){
			fetch(`${config.app.api}items?media=1&categories=1` )
			.then( result => result.json() )
			.then( data =>  { 
				setPostsAll(data);
				setPosts(data);
				setPostsLoaded(true);
			})
			.catch( console.error );
		}
		// load categories once
		if( categories.length <= 0 ){
			fetch( `${config.app.api}categories` )
			.then( result => result.json())
			.then( data => setCategories(data))
			.catch( console.error );
		}
	// eslint-disable-next-line
	}, []);

	// filter by category
	useEffect(()=>{
		if( category && category.hasOwnProperty('id') ){
			let filteredPosts = postsAll.filter( post => post.hasOwnProperty('categories') && post.categories.includes(category.id));
			setPosts( filteredPosts );
		}else{
			setPosts( postsAll );
		}
	// eslint-disable-next-line
	}, [category]);


	// cart initial
	const initialCart = [];
	const initialOptions = {deliverySameAsAddress: true};
	// cart actions
	const [cartTotal, 	setCartTotal]		= useState(0);
	const [cartQty, 	setCartQty]			= useState(0);
	const [cartOptions, setCartOptions]		= useState(initialOptions);
	const [cartErrors, 	setCartErrors]		= useState([]);
	const [cartDisabled, setCartDisabled]	= useState(false);
	const [cartTrigger, setCartTrigger]		= useState(false);
	
	// cart reducer
	const cartReducer = useCallback( (state, action) => {
		setCartTrigger(new Date());
		switch(action.type){
			case 'add':
				return _.unionBy([...state, {item: action.payload.item,
											variant: action.payload.variant,
											qty: 1}
				], 'item')
			case 'increment': {
					const itemIndex = _.findIndex( state, (e) => {
						return e.item.id === action.payload.item.id;
					}, 0);
					state[itemIndex].qty += 1;
					return state; 
				}
			case 'decrement': {
					const itemIndex = _.findIndex(state, (e) => {
						return e.item.id === action.payload.item.id;
					}, 0);
					if( state[itemIndex].qty > 1 ){
						state[itemIndex].qty -= 1;
						return state;
					}else{
						return state;
					} 
				}
			case 'option': {
					const itemIndex = _.findIndex(state, (e) => {
						return e.item.id === action.payload.item.id;
					}, 0);
					state[itemIndex]['variant'] = action.payload.variant;
					return state;
				}
			case 'remove':
				return state.filter(item => item.item.id !== action.payload.item.id)
			case 'clear':
				return initialCart;
			default:
				break;
		}
	//eslint-disable-next-line
	}, [cartTotal]);
	// reducer
	const [cart,		cartDispatch]		= useReducer(cartReducer, initialCart);
	
	// cart items
	useEffect(() => {
		//setCartTrigger(new Date());
		// get total
		const totalCart = cart.reduce( 
			(total, item) => {return (item.variant.price * item.qty) + total}
		,0);
		setCartTotal( priceFormat(totalCart) );

		// get qty
		const totalQty = cart.reduce( 
			(total, item) => {return item.qty + total}
		,0);
		setCartQty(totalQty);

	// eslint-disable-next-line
	},[cartTrigger])

	// cart options
	useEffect(() => {
		// errors
		const cartErr = [];
		if( !cartOptions.hasOwnProperty('name') || (cartOptions.hasOwnProperty('name') && cartOptions.name === '') ){
			cartErr.push({key: 'name', msg: "Please enter a name"});
		}
		if( !cartOptions.hasOwnProperty('email') || (cartOptions.hasOwnProperty('email') && cartOptions.email === '') ){
			cartErr.push({key: 'email', msg: "Please enter a valid email"});
		}
		if( !cartOptions.hasOwnProperty('address') || (cartOptions.hasOwnProperty('address') && cartOptions.address === '') ){
			cartErr.push({key: 'address', msg: "Please enter an address"});
		}
		if( !cartOptions.hasOwnProperty('city') || (cartOptions.hasOwnProperty('city') && cartOptions.city === '') ){
			cartErr.push({key: 'city', msg: "Please enter an city"});
		}
		if( !cartOptions.hasOwnProperty('country') || (cartOptions.hasOwnProperty('country') && cartOptions.country === '') ){
			cartErr.push({key: 'country', msg: "Please select a country"});
		}
		// delivery address
		if( cartOptions.deliverySameAsAddress === false ){
			if( !cartOptions.hasOwnProperty('deliveryName') || (cartOptions.hasOwnProperty('deliveryName') && cartOptions.deliveryName === '') ){
				cartErr.push({key: 'deliveryName', msg: "Please enter a name"});
			}
			if( !cartOptions.hasOwnProperty('deliveryAddress') || (cartOptions.hasOwnProperty('deliveryAddress') && cartOptions.deliveryAddress === '') ){
				cartErr.push({key: 'deliveryAddress', msg: "Please enter an address"});
			}
			if( !cartOptions.hasOwnProperty('deliveryCity') || (cartOptions.hasOwnProperty('deliveryCity') && cartOptions.deliveryCity === '') ){
				cartErr.push({key: 'deliveryCity', msg: "Please enter a city"});
			}
		}
		setCartErrors(cartErr);

		// debug
		/*
		if( cart.length > 0 ){
			console.log("cart:");
			console.table( cart );
		}
		if( Object.keys(cartOptions).length > 0 ){
			console.log("cartOptions:");
			console.table( cartOptions );
		}
		if( cartErr.length > 0 ){
			console.log("errors:");
			console.table( cartErr );
		}
		*/

	// eslint-disable-next-line
	},[cartOptions])

	const resetCart = () => {
		setCartOptions(initialOptions);
		cartDispatch({type: 'clear'});
	}


	// set value
	const providerValue = { // settings
							settings: settings,
							// posts
							post: post,
							posts: posts,
							postsLoaded: postsLoaded, 
							// filters
							categories: categories,
							category: category,
							// setters
							setPost: setPost,
							setCategory: setCategory,
							// cart
							cart: cart,
							cartDispatch: cartDispatch,
							cartTotal: cartTotal,
							cartQty: cartQty,
							cartOptions: cartOptions,
							setCartOptions: setCartOptions, 
							cartErrors: cartErrors, 
							resetCart: resetCart,
							cartDisabled: cartDisabled,
							setCartDisabled: setCartDisabled,
							// loader
							loaderStatus: loaderStatus,
							loaderSetStatus: loaderSetStatus,
							// stripe
							stripePromise: props.stripePromise
	};

    return (
		<DataContext.Provider value={providerValue} >
			{props.children}
		</DataContext.Provider>
	)
}

export default DataProvider;