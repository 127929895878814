import React from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { createBrowserHistory } from 'history'

import Menu from "./components/Menu";
import Loading from './components/Loading'
//import CustomMouse from './components/CustomMouse'

import AllPosts from "./pages/AllPosts";
import SinglePost from "./pages/SinglePost";

import DataProvider from "./components/dataProvider";

import config from './components/Config'

import Shop from "./components/Shop";
//import { loadStripe } from "@stripe/stripe-js";
//const stripePromise = loadStripe(config.stripe.publishKey);

function App() {

	const history = createBrowserHistory();

	return (
		
		<div className="container">

			<DataProvider /*stripePromise={stripePromise} */>

				<Router history={history} >

					<Route render={ ({location}) =>

						<React.Fragment>

							{ config.cart.useCart === true && <Shop /> }

							<Menu location={location} />

							<Loading />

							<TransitionGroup>

								<Switch>

									<Route 	path={'/'} exact >
										{({match}) => (
											<CSSTransition 	in={(match !== null)} 
															appear
															unmountOnExit
															classNames="fade" 
															timeout={{appear: 1000, enter:200, exit:100}} >
												<AllPosts />
											</CSSTransition>
										)}
									</Route>

									<Route path={'/:slug'} >
										{({match,slug}) => (
											<CSSTransition 	in={(match !== null)} 
															appear
															unmountOnExit
															classNames="fade" 
															timeout={{appear: 1000, enter: 200, exit: 100}} >
												<SinglePost slug={slug} />
											</CSSTransition>
										)}
									</Route>

								</Switch>

							</TransitionGroup>

						</React.Fragment>

					} />
					
				</Router>

			</DataProvider>

		</div>
		
	)
};


export default App;