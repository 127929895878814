import React from 'react';
import {urlFor} from '../components/MediaImage'


function HelloImage( props ){

	return (
		
		<div 	className={props.className}
				style={{ backgroundImage: `url(${urlFor( props.path, {type: 'homepage'})})`}} >
		</div>

	)

}

export default HelloImage;