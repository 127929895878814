import React,{useState,useEffect, useRef} from 'react';
import Spinner from './Spinner'
import config from './Config'
import { MdPlayArrow, MdPause } from "react-icons/md";

function MediaAudio( props ){

	const refMediaElem = useRef(null);
	const refAudioElem = useRef(null);
	const refProgressElem = useRef(null);

	const [isLoaded, 	setIsLoaded] = useState("loading");
	const [isPlaying, 	 setIsPlaying] = useState(false);

	useEffect(()=>{
		//
	},[])

	const toggleAudio = () => {
		if( isPlaying ){
			refAudioElem.current.pause();
			setIsPlaying(false);
			refProgressElem.current.style.transform =  `scale(0)`;
		}else{
			refAudioElem.current.play();
			setIsPlaying(true);
		}	
	}

	const showProgress = (time) => {
		let progress = ( refAudioElem.current.currentTime / refAudioElem.current.duration );
		refProgressElem.current.style.transform =  `scale(${progress})`;
	}


	return (
		
		<React.Fragment>

			<figure data-status={isLoaded} 
					data-type={`audio`}
					className={ (isPlaying) ? "active" : ""} 
					ref={refMediaElem}>

				{ isLoaded !== "loaded" && <Spinner /> }

				<audio 	onCanPlay={ () => { setIsLoaded("loaded") } }
						onError={ () => { setIsLoaded("error") } }
						onEnded={ () => { toggleAudio() } }
						onTimeUpdate={ (d) => { showProgress(d) }}
						className="post__audio"
						ref={refAudioElem}
						src={`${config.app.uploads}${props.path}`}
						// onClick={ ((e)=>{ console.log('audio click ', e.target.getAttribute('src') ) }) }
						{ ...props.data }
				/>

				<div 	className={`cta__toggle ${ (isPlaying) ? "active" : "" }`}
						onClick={toggleAudio}>

					{ isPlaying && <MdPause /> }
					{ ! isPlaying && <MdPlayArrow /> }

					<div 	className={`post__audio-progress ${ (isPlaying) ? "active" : "" }`}
							ref={refProgressElem}></div>

				</div> 

				

				<figcaption>
					{ props.caption !== null && props.caption }
				</figcaption> 

				{props.children}

			</figure>

		</React.Fragment>

	)

}

export default MediaAudio;