import React,{useState} from 'react';
import Spinner from './Spinner'
import config from './Config'

function MediaVideo( props ){

	const [isLoaded, setIsLoaded] = useState("loading");

	return (
		
		<React.Fragment>

			<figure data-status={isLoaded} 
					data-type={`video`} >

				{ isLoaded !== "loaded" && <Spinner /> }

				<video 	onLoadedData={ () => { setIsLoaded("loaded") } }
						onError={ () => { setIsLoaded("error") } }
						className="post__video"
						src={`${config.app.uploads}${props.path}`}
						controls
						// onClick={ ((e)=>{ console.log('video click ', e.target.getAttribute('src') ) }) }
						{ ...props.data }
				/>

				<figcaption>
					{ props.caption !== null && props.caption }
				</figcaption> 

				{props.children}

			</figure>

		</React.Fragment>

	)

}

export default MediaVideo;