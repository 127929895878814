import React, {useEffect, useRef} from 'react';

function TextBox( props ){

	const refContainer = useRef(null);
	const refWrapper = useRef(null);

	useEffect(() => {
		//let textboxCharCount = props.data.subtitle.length + props.data.description.replace(/<[^>]*>?/gm, '').length;
		if( /*textboxCharCount > 1000 ||*/  refWrapper.current.offsetHeight >= (window.innerHeight-220) ){ // 220 = header + title + bottom space
			refContainer.current.classList.add('textbox--scrollable');
		}else{
			refContainer.current.classList.remove('textbox--scrollable');
		}
	},[props])

	return (
		
		<div 	className="textbox" 
				ref={refContainer}>

			<div 	className="wrapper" 
					ref={refWrapper}>

				{ props.data.subtitle !== undefined && props.data.subtitle !== "" && 
					<div className="post__subtitle" dangerouslySetInnerHTML={{__html: props.data.subtitle}}></div>
				}

				{ props.data.description !== undefined && props.data.description !== "" && 
					<div className="post__wyswyg" dangerouslySetInnerHTML={{__html: props.data.description}}></div>
				}

			</div>

		</div>

	)

}

export default TextBox;