import React,{useEffect,useRef} from 'react'
// import {Link } from 'react-router-dom'
import {animateHeading} from '../components/Helpers'
import HelloGallery from '../components/HelloGallery'
import FilterItems from '../components/FilterItems'
import { MdMail, MdPhone } from 'react-icons/md';
import { SiFacebook, SiInstagram, SiLinkedin, SiBehance, SiTwitter } from "react-icons/si";

// https://react-icons.github.io/react-icons/icons?name=si


export default function Hello(props){

	const refTitle = useRef(null);

	useEffect( () => {
		animateHeading( props.settings.name, refTitle );
	// eslint-disable-next-line
	},[] )

	return (

		<div className="hello">

			<div className="hello__overlay" ></div>

			{ props.settings && props.settings.bg_media !== undefined && props.settings.bg_media.length > 0 && 
				<HelloGallery items={props.settings.bg_media} />
			}

			<div className="hello__wrapper">

				{ props.settings && props.settings.name !== '' && 
					<h1 className="hello__title " ref={refTitle}>{}</h1> 
				}

				<div className="hello__filters">
					{ props.categories && props.categories.length > 0 && 
						<FilterItems 	items={props.categories} 
										type="category" 
										updateFilter={props.updateFilter} />
					}
				</div>
				
				{ props.settings && props.settings.slogan !== '' && 
					<p className="hello__text">{props.settings.slogan}</p> 
				}
				
				{ props.settings && 
					<p className="hello__icons">
						{ props.settings.email !== undefined && props.settings.email !== '' && 
							<a href={`mailto:${props.settings.email}`}><MdMail /></a> }
						{ props.settings.phone !== undefined && props.settings.phone !== '' && 
							<a href={`tel:${props.settings.phone}`}><MdPhone /></a> }
						
						{ props.settings.social_facebook !== undefined && props.settings.social_facebook !== '' && 
							<a href={`${props.settings.social_facebook}`} target="_blank" rel="noopener noreferrer"><SiFacebook /></a> }
						{ props.settings.social_instagram !== undefined && props.settings.social_instagram !== '' && 
							<a href={`${props.settings.social_instagram}`} target="_blank" rel="noopener noreferrer"><SiInstagram /></a> }
						{ props.settings.social_linkedin !== undefined && props.settings.social_linkedin !== '' && 
							<a href={`${props.settings.social_linkedin}`} target="_blank" rel="noopener noreferrer"><SiLinkedin /></a> }
						{ props.settings.social_twitter !== undefined && props.settings.social_twitter !== '' && 
							<a href={`${props.settings.social_twitter}`} target="_blank" rel="noopener noreferrer"><SiTwitter /></a> }
						{ props.settings.social_behance !== undefined && props.settings.social_behance !== '' && 
							<a href={`${props.settings.social_behance}`} target="_blank" rel="noopener noreferrer"><SiBehance /></a> }
					</p>
					}

				{ props.settings && props.settings.copyright !== '' && 
					<p className="hello__subtext ">{props.settings.copyright}</p> 
				}

			</div>
			
		</div>

	)
}