import React, {useEffect, useState} from 'react'
import {Link } from 'react-router-dom'
import MediaImage from '../components/MediaImage'
import {recognizeMedia} from '../components/Helpers'
import _ from 'lodash';
//import { GrCamera, GrMicrophone, GrVideo } from "react-icons/gr";

export default function GridItem(props){


	const [mediaInfo, setMediaInfo] = useState([]);

	const item = props.item;
	const itemFile = `item/${item.id}/${item.cover_image}`;

	useEffect( ()=>{
		let mediaTypes = [];
		item.media.forEach( itemMedia => {
			mediaTypes.push( recognizeMedia( itemMedia ) );
		})
		setMediaInfo( _.uniqBy(mediaTypes) );
	// eslint-disable-next-line
	}, [item]);

	return (

		<div key={item.slug.current} className="posts__item">

			<Link 	className="posts__item-link" 
					to={'/' + item.slug } >

				{ item.cover_image && 
					<MediaImage {...{	path: 	itemFile,
										alt:	`Gallery ${item.title}` }} />
				}

				<h3 className="posts__item-title">
					
					{ item.title}

					<small>
						{ mediaInfo.map( (itm,idx) => {
							return <img key={idx}
										src={`/assets/icons/media_${itm}.svg`}
										alt={`Media ${itm}s`} />
						}) }

						{ item.media.length > 0 && false &&
							<React.Fragment>{ item.media.length } { (item.media.length > 1 ? `items`: `item`) }</React.Fragment>
						}
					</small>

				</h3>

			</Link>
			
		</div>

	)
}