import React, {useEffect,useState} from 'react';
import MediaImage from '../components/MediaImage'
import MediaAudio from '../components/MediaAudio'
import MediaVideo from '../components/MediaVideo'
import {recognizeMedia} from '../components/Helpers'


function Media( props ){

	const [params, setParams] = useState({});

	useEffect( ()=>{

		// video
		if( recognizeMedia(props.item) === "video" ){
			setParams({	type: "video",  ...props })
		}
		// audio
		if( recognizeMedia(props.item) === "audio" ){
			setParams({	type: "audio", ...props })
		}
		// image
		if( recognizeMedia(props.item) === "image" ){
			setParams({	type: "image", ...props })
		}
		
	// eslint-disable-next-line
	}, []);

	return (
		
		<React.Fragment>

			{ params.type === "image" && 
				<MediaImage {...params} />
			}

			{ params.type === "audio" && 
				<MediaAudio {...params} />
			}

			{ params.type === "video" && 
				<MediaVideo {...params} />
			}

		</React.Fragment>

	)

}

export default Media;