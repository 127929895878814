import React,{useContext, useEffect,useRef,useState} from 'react'
import DataContext from './dataContext'
import config from '../components/Config'
import MediaImage from '../components/MediaImage'
import Stripe from '../components/Stripe'
import {parseApiVariants,priceFormat} from '../components/Helpers';
import _ from 'lodash';




// cart 
function ShopBar(props){

	const contextData = useContext( DataContext );

	useEffect(()=>{},[contextData]);

	const shopBarClass = ( contextData.cart.length > 0 ) ? `active`: `inactive`;

	return (
		<div 	className={`shop-bar shop-bar--${shopBarClass}`}
				onClick={props.openShop}>
			{`${config.cart.barLabel} ${config.cart.currency}${contextData.cartTotal} `}
			<small>({contextData.cartQty})</small>
		</div>
	)

}

function CartMenu(props){

	const contextData = useContext( DataContext );

	return (
		<React.Fragment>

			<span 	className="shop__close"
					onClick={props.closeShop}>
				{config.cart.closeLabel}
			</span>

			<div className="shop__header">

				{/* cart current */}
				{ props.shopStep === "cart" &&
					<span 	className="shop__header-cart c-black active">{config.cart.cartLabel}</span>
				}
				{/* cart disabled 
				{ props.shopStep !== "cart" && props.cartDisabled === true &&  
					<span 	className="shop__header-cart c-gray disabled">{config.cart.cartLabel}</span>
				}
				*/}
				{/* cart not current but accessable */}
				{ props.shopStep !== "cart" && /*props.cartDisabled === false && */
					<span 	onClick={ ()=>{props.setStep('cart')} } className="shop__header-cart c-black">{config.cart.cartLabel}</span>
				}

				{/* checkout step link */}
				{ props.shopStep === "checkout" && contextData.cartTotal > 0 &&
					<span 	className="shop__header-checkout c-black active" >{config.cart.checkoutLabel} </span> 
				}
				{ props.shopStep !== "checkout" && contextData.cart.length === 0 &&
					<span 	className="shop__header-checkout c-gray disabled" >{config.cart.checkoutLabel} </span> 
				}
				{ props.shopStep !== "checkout" && contextData.cartTotal > 0 &&
					<span 	className="shop__header-checkout c-black" onClick={ ()=>{props.setStep('checkout')} } >{config.cart.checkoutLabel} </span> 
				}

				{/* payment step link */}
				{ props.shopStep === "payment" && 
					<span 	className="shop__header-payment c-black active" >{config.cart.paymentLabel} </span> 
				}
				{ props.shopStep !== "payment" && ( contextData.cartErrors.length > 0 || contextData.cartTotal === 0 ) &&
					<span 	className="shop__header-payment c-gray disabled" >{config.cart.paymentLabel}</span> 
				}
				{ props.shopStep !== "payment" && contextData.cartErrors.length === 0 && contextData.cartTotal > 0 && 
					<span 	className="shop__header-payment c-black" onClick={ ()=>{props.setStep('payment')} } >{config.cart.paymentLabel} </span> 
				}

			</div>

		</React.Fragment>
	)

}

function CartFooter(props){

	const contextData = useContext( DataContext );

	useEffect(()=>{
		//
	},[contextData.cartTotal]);

	const clearCart = () => {
		contextData.cartDispatch({type: 'clear'});
	}

	return (
		<div className="shop__footer">

			{/* cart:full */}
			{ props.shopStep === "cart" && contextData.cart.length > 0 && 
				<React.Fragment>
					<span className="cart__footer-title">
						{config.cart.subtotalLabel}
						<span className="divider divider--quarter"></span>
						{config.cart.currency}{contextData.cartTotal}
						<span className="divider divider--quarter"></span>
						<small>({contextData.cartQty} items)</small>
					</span>
				
					<span className="cart__clear" onClick={clearCart}>
						{config.cart.clearLabel}
					</span>
				</React.Fragment>
			}

			{/* cart:empty */}
			{ props.shopStep === "cart" && contextData.cart.length === 0 && 
				<React.Fragment>
					<span className="cart__footer-title" onClick={clearCart}>
						Your cart is empty.
					</span>
				</React.Fragment>
			}

			{/* checkout */}
			{ props.shopStep === "checkout" && 
				<React.Fragment>
					<span className="checkout__text">
						Please fill out this form so we can deliver your products.
					</span>
				</React.Fragment>
			}

			{/* payment */}
			{ props.shopStep === "payment" && 
				<React.Fragment>
					<span className="checkout__text">
						* Please check the information once more before making final payments.
					</span>
				</React.Fragment>
			}

			{/* thanks */}
			{ props.shopStep === "thanks" && 
				<React.Fragment>
				</React.Fragment>
			}

		</div>
	)
}

function CartItems(props){

	const contextData = useContext( DataContext );

	return (
		contextData.cart.length > 0 && 
			<React.Fragment>

				<div className="cart__items">
					{ contextData.cart.map( (cartItem,idx) => {
						return <CartItem 	key={`cart-item-${idx}`} 
											cartItem={cartItem} />
					}) }
				</div>

				<span className="divider divider--vertical"></span>

				<div>
					<button onClick={ () => {props.setStep('checkout')} }
							className="btn btn--big" >
						Proceed to Checkout
					</button>
				</div>

			</React.Fragment>
	)
}

function CartItem(props){

	const contextData = useContext( DataContext );

	const cartItem = props.cartItem;
	// parse pricing from api
	const parsedVariants = parseApiVariants( cartItem.item.pricings );
	// item thumb
	const itemFile = `item/${cartItem.item.model_id}/${cartItem.item.data}`;

	// cart reducer actions
	const incrementCart = (item) => {
		contextData.cartDispatch({type: 'increment', payload: item});
	}
	const decrementCart = (item) => {
		contextData.cartDispatch({type: 'decrement', payload: item});
	}
	const removeFromCart = (item) => {
		contextData.cartDispatch({type: 'remove', payload: item});
	}
	const optionCart = (payload) => {
		contextData.cartDispatch({type: 'option', payload: payload});
	}
	

	return (
		<span 	className="cart__item">

				<MediaImage {...{alt: cartItem.item.name,
								path: itemFile,
								className: "cart__thumb"}  } />

				<div className="cart__item-head">
					<span className="cart__item-total">{config.cart.currency}{priceFormat(cartItem.variant.price*cartItem.qty)}</span>
					<span className="cart__item-info">{cartItem.variant.info}</span>
				</div>

				<div className="cart__item-controls">
					
					{/* cart item qty */}
					{ config.cart.useQty && 
						<div className="cart__item-ctas">
							<div>
								<span className="cart__item-decrease btn" onClick={()=>{ decrementCart(cartItem) }}>-</span>
								<span className="cart__item-counter">{cartItem.qty}</span>
								<span className="cart__item-increase btn" onClick={()=>{ incrementCart(cartItem) }}>+</span>
							</div>
							<div>
								<span className="cart__item-remove btn" onClick={()=>{ removeFromCart(cartItem) }}>{config.cart.removeLabel}</span>
							</div>
						</div>
					}

					{/* cart item variants */}
					{ config.cart.useVariants && 
						<div className="cart__item-options">

							{/* 
							<select onChange={(e) => { optionCart({ item: cartItem.item, variant: parsedVariants[e.target.value]}) }}
									defaultValue={parsedVariants[0]}>
								{ parsedVariants.map( (pricing,idx) => {
										return <option 	key={idx} 
														value={idx} >
													{pricing.name} - {pricing.info} - {pricing.price}
											</option>
									})
								}
							</select>
							*/}

							
							{ parsedVariants.map( (item,idx) => {
								const btnClass = ( cartItem.variant.name === item.name ) ? `active` : `inactive`;
								return <span 	key={`variant-${idx}`} 
												onClick={(e) => { optionCart({item: cartItem.item, variant: item }) }} 
												className={`btn btn--${btnClass}`} >
											{item.name} / {config.cart.currency}{priceFormat(item.price)}
										</span>
									})
							}

						</div>
					}
				
				</div>

			</span>
	)

}

// checkout
function CheckoutForm(props){

	const contextData = useContext( DataContext );

	const deliveryRef = useRef(null);
	const deliveryCheckboxRef = useRef(null);
	const submitRef = useRef(null);

	const [deliverySame, setDeliverySame] = useState( contextData.cartOptions.deliverySameAsAddress );

	useEffect(()=>{
		//props.submitCheckout(false);
	// eslint-disable-next-line
	},[contextData.cartErrors]);

	// methods
	const showError = (errorKey) => {
		const errorObj = _.find(contextData.cartErrors, ['key', errorKey] );
		return ( props.checkoutSubmitted && errorObj !== undefined ) 
			? <span className="checkout__error">{errorObj.msg}</span>
			: '';
	}

	const toggleDelivery = (evt) => {
		const deliveryState = ( evt.target.checked ) ? true : false;
		setDeliverySame(deliveryState);
		contextData.setCartOptions({...contextData.cartOptions, ...{deliverySameAsAddress: deliveryState}});
		if( deliveryState === true ){
			deliveryRef.current.classList.add('hidden');
		}else{
			deliveryRef.current.classList.remove('hidden');
		}
		
	}

	const submitCheckout = (evt) => {
		evt.preventDefault();
		let formElems = {};
		[].slice.call(evt.target).forEach( (elem,index) => {
			if( elem.type !== 'submit' && elem.name !== 'deliverySameAsAddress' ){
				formElems[elem.name] = elem.value;
			}
		});
		formElems[ 'deliverySameAsAddress' ] = ( deliverySame ) ? true : false;
		contextData.setCartOptions( formElems );
		// 
		props.submitCheckout(true);
	}

	// consts
	const deliveryClass = 		deliverySame ? 'hidden' : '';

	return (

		<form onSubmit={(e) => submitCheckout(e) }>
			<div>
				Email: <input 	type="text" 
								name="email" 
								defaultValue={contextData.cartOptions.email} />
				{ showError('email') }
			</div>
			<div>
				Name: <input 	type="text"  
								name="name" 
								defaultValue={contextData.cartOptions.name} />
				{ showError('name') }
			</div>
			<div>
				Address: <input type="text"  
								name="address" 
								defaultValue={contextData.cartOptions.address} />
				{ showError('address') }
			</div>
			<div>
				City: <input 	type="text" 
								name="city"
								defaultValue={contextData.cartOptions.city} />
				{ showError('city') }
			</div>
			<div>
				Country: <select 	defaultValue={contextData.cartOptions.country} 
									name="country" >
					<option value="">-</option>
					<option value="hr">hr</option>
					<option value="de">de</option>
					<option value="it">it</option>
					<option value="fr">fr</option>
				</select>
				{ showError('country') }
			</div>
			<div>
				<input 	type="checkbox" 
						name="deliverySameAsAddress"
						onChange={(e)=> {toggleDelivery(e)}} 
						defaultChecked={contextData.cartOptions.deliverySameAsAddress} 
					/> Delivery address is same as user address
			</div>
			<div ref={deliveryRef} className={`${deliveryClass}`}>
				<div>
					Delivery Name: <input 	type="text" 
											ref={deliveryCheckboxRef} 
											name="deliveryName"
											defaultValue={contextData.cartOptions.deliveryName} />
					{ showError('deliveryName') }
				</div>
				<div>
					Delivery Address: <input 	type="text" 
												name="deliveryAddress"
												defaultValue={contextData.cartOptions.deliveryAddress} />
					{ showError('deliveryAddress') }
				</div>
				<div>
					Delivery City: <input 	type="text" 
											name="deliveryCity"
											defaultValue={contextData.cartOptions.deliveryCity} />
					{ showError('deliveryCity') }
				</div>
			</div>
			<br />
			<div>
				<button ref={submitRef} 
						className="btn btn--big" 
						type="submit">
					Proceed to Payment
				</button>
			</div>
		
		</form>

	);

}

function CheckoutData(props){

	const contextData = useContext( DataContext );

	return (
		<React.Fragment>
			<div className="order-data">
				<label className="order-data__head">Customer Info:</label><br />
				<label>Email:</label> {contextData.cartOptions.email}<br />
				<label>Name:</label> {contextData.cartOptions.name}<br />
				<label>Address:</label> {contextData.cartOptions.address}<br />
				<label>City:</label> {contextData.cartOptions.city}<br />
				<label>Country:</label> {contextData.cartOptions.country}<br />
			</div>

			{ contextData.cartOptions.deliverySameAsAddress === false && 
				<div className="order-data--delivery">
					<label className="order-data__head">Delivery Info:</label><br />
					<label>Name:</label> {contextData.cartOptions.deliveryName}<br />
					<label>Address:</label> {contextData.cartOptions.deliveryAddress}<br />
					<label>City:</label> {contextData.cartOptions.deliveryCity}<br />
				</div>
			}
		</React.Fragment>
	)
}

export default function Shop(props){

	const contextData = useContext( DataContext );

	// refs
	const shopRef = useRef(null);

	// states
	const [shopStep, 			setShopStep] = useState("cart");
	const [cartDisabled, 		setCartDisabled] = useState(false);
	const [checkoutSubmitted, 	setCheckoutSubmitted] = useState(false);

	// methods
	const openShop = () => {
		shopRef.current.classList.add('shop--active');
	}
	const closeShop = () => {
		shopRef.current.classList.remove('shop--active');
	}
	const setStep = (step) => {
		setShopStep(step);
	}
	const submitCheckout = () => {
		setCheckoutSubmitted(true);
	}
	const resetShop = () => {
		setShopStep("thanks");
		setCheckoutSubmitted(false);
		setCartDisabled(false);
	}
	const closeThanks = () => {
		closeShop();
		setTimeout(() => { 
			setShopStep("cart"); 
		},1000);
	}

	useEffect( () => {
		contextData.setCartDisabled(cartDisabled);
	// eslint-disable-next-line
	},[cartDisabled])

	// disable cart if submitted checkout with no errors in
	useEffect(()=>{
		if( checkoutSubmitted === true && contextData.cartErrors.length === 0 ){
			setCartDisabled(true);
			setCheckoutSubmitted(false);
			setStep('payment');
		}
	// eslint-disable-next-line
	},[contextData.cartErrors, checkoutSubmitted])

	// click outside shop
	useEffect(() => {
        function handleClickOutside(event) {
			if (	shopRef.current 
					&& !shopRef.current.contains(event.target) 
					&& !event.target.classList.contains("add-to-cart")
			) {
				closeShop();
            }
		}
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
		};
	// eslint-disable-next-line
	}, [shopRef]);


	return (

		<React.Fragment>

			<ShopBar openShop={openShop} />

			{/* --- ref container --- */}
			<div className={`shop`} ref={shopRef}>

				{/* --- cart --- */}
				{ shopStep === "cart" && 
					<div className={`cart`} >
						<div className="wrapper">
							<CartMenu 	shopStep={shopStep}
										setStep={setStep}
										closeShop={closeShop}
										cartDisabled={cartDisabled} />
							<CartItems 	setStep={setStep} />
							<CartFooter shopStep={shopStep} />
						</div>
					</div>
				}

				{/* --- checkout --- */}
				{ shopStep === "checkout" && 
					<div className={`checkout`}>
						<div className="wrapper">
							<CartMenu 	shopStep={shopStep}
										setStep={setStep}
										closeShop={closeShop}
										cartDisabled={cartDisabled} />
							<CheckoutForm 	setStep={setStep} 
											checkoutSubmitted={checkoutSubmitted}
											submitCheckout={submitCheckout} />
							<CartFooter 	shopStep={shopStep} />
						</div>
					</div>
				}

				{/* --- payment --- */}
				{ shopStep === "payment" && 
					<div className={`payment`}>
						<div className="wrapper">
							<CartMenu 	setStep={setStep} 
										shopStep={shopStep}
										closeShop={closeShop}
										cartDisabled={cartDisabled} />
							<CheckoutData />
							<Stripe 	cartTotal={contextData.cartTotal}
										cart={contextData.cart}
										cartOptions={contextData.cartOptions}
										stripePromise={contextData.stripePromise} 
										resetShop={resetShop} />
							<CartFooter 	shopStep={shopStep} />
						</div>
					</div>
				}

				{/* --- sucess --- */}
				{ shopStep === "thanks" && 
					<div className={`thanks`}>
						<div className="wrapper">
							<p className="thanks__title">{config.order.success.title}</p>
							<p className="thanks__text">{config.order.success.text}</p>
							<button className="btn thanks__close" onClick={closeThanks}>{config.order.success.closeLabel}</button>
						</div>
					</div>
				}

			</div>

		</React.Fragment>

	)
	
}