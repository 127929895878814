import React, { useState, useEffect, useContext,useRef } from "react";
import {Elements,CardElement,PaymentRequestButtonElement,useStripe} from '@stripe/react-stripe-js';
import DataContext from './dataContext'
import config from '../components/Config'
import { priceFormat, orderPriceFormat } from '../components/Helpers'
import Spinner from '../components/Spinner'

import {urlFor} from '../components/MediaImage'


const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
				margin: "20px",
				color: "#222222",
				fontFamily: '"Josephin Sans", sans-serif',
				fontSmoothing: "antialiased",
				fontSize: "14px",
				"::placeholder": {
				color: "#222222",
			},
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a",
		},
	},
};

function CheckoutForm(props){

	const contextData = useContext( DataContext );
	const stripe = useStripe();

	const [paymentRequest, setPaymentRequest] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const paymentRef = useRef(null);
	
	function makePayment(){

		// disable payment button
		paymentRef.current.innerText = "Payment in process...";
		paymentRef.current.disabled = true;

		setIsLoading(true);

		stripe.confirmCardPayment(
			props.clientSecret,
			{
				payment_method: "pm_card_visa"
			}
		).then(function(result){

			setIsLoading(false);

			if (result.error) {
				console.log('EROR', result.error.message);
			} 
			else {
				if (result.paymentIntent.status === 'succeeded') {
					console.log( "SUCCESS! Thanks! ");

					fetch("http://localhost/photo-gallery/server/save-order.php", {
							method: 'POST',
							headers: { 	'Accept': 'application/json',
										'Content-Type': 'application/json' },
							body: JSON.stringify({	client: props.cartOptions, 
													order_id: result.paymentIntent})
						}
					)
					.then( response => response.json() )
					.then( data => console.log(data) );

					// reset all order data
					props.resetShop();
					contextData.resetCart();
				}
			}
		});
	}

	// check payment
	useEffect(() => {
		if (stripe) {
			const pr = stripe.paymentRequest({
				country: "NL",
				currency: 'usd',
				total: {
					label: `Payment`,
					amount: orderPriceFormat(props.cartTotal)
				},
				requestPayerName: true,
        		requestPayerEmail: true
			});

			// Check the availability of the Payment Request API.
			pr.canMakePayment().then(result => {
				if (result) {
					setPaymentRequest(pr);
				}
			});
		}
		// eslint-disable-next-line
	}, [stripe]);
	
	//  Apple Pay, Google Pay, Microsoft Pay, and the Payment Request API
	if (paymentRequest) {

		paymentRequest.on('paymentmethod', async (ev) => {
			const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
				props.clientSecret,
				{payment_method: ev.paymentMethod.id},
				{handleActions: false}
			);
		  
			if (confirmError) {
			  	ev.complete('fail');
			} 
			else {
				ev.complete('success');
				if (paymentIntent.status === "requires_action") {
					setIsLoading(false);
					const {error} = await stripe.confirmCardPayment(props.clientSecret);
					if (error) {
						console.log('error', error);
					} else {
						console.log('success!');
					}
				} else {
					console.log('success!', paymentIntent);
				}
			}
		});

		return <PaymentRequestButtonElement options={{paymentRequest}} />
	}

	// Use a traditional payment form
	return <React.Fragment>
			<CardElement options={CARD_ELEMENT_OPTIONS} />
			<br />
			<button ref={paymentRef}
					className="btn btn--big" 
					id="checkout-button" 
					role="link" 
					onClick={makePayment} >
				Pay {config.cart.currency}{props.cartTotal}
			</button>
			{ isLoading &&  <Spinner /> }
		</React.Fragment>
	
}

function CheckoutWrap(props){
	return (
		<section className="stripe-card">
			<Elements stripe={props.stripePromise}>
				<CheckoutForm 	cartTotal={props.cartTotal}
								cartOptions={props.cartOptions}
								resetShop={props.resetShop}
								clientSecret={props.clientSecret} />
			</Elements>
		</section>
	)
};
		
export default function Stripe(props){

	const [clientSecret, setClientSecret] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	// 1. initial effect on load : create intent and fetch clientSecret
	useEffect(() => {

		// initial payload
		const payload = {};
		// total
		payload.total = orderPriceFormat(props.cartTotal);
		// options
		Object.entries(props.cartOptions).map( item => {
			const itemName = item[0];
			payload[`${itemName}`] = item[1];
			return false;
		});
		// products
		Object.values(props.cart).forEach( (product,idx) => {
			let productTotal = priceFormat( product.qty * product.variant.price );
			let productImage = `${product.item.model_type}/${product.item.model_id}/${product.item.data}`;
			let productData = [];

			productData.push( `TOTAL: ${priceFormat(product.variant.price)} * ` );
			productData.push( `${product.qty} = ` );
			productData.push( `${productTotal}` );
			productData.push( ` / ` );
			productData.push( `VARIANT NAME: ${product.variant.name}, ` );
			productData.push( `INFO: ${product.variant.info}` );
			productData.push( ` / ` );
			productData.push( `IMG: ${urlFor(productImage, {type: "thumb"})}` );
			payload[`product ${product.item.id}`] = productData.join('');
		});


		(async function createIntent() {
			//console.log( "create intent and get intent:clientSecret" );
			setIsLoading(true);
			const rawResponse = await fetch(
				"http://localhost/photo-gallery/server/create-intent.php", {
					method: 'POST',
					headers: { 	'Accept': 'application/json',
								'Content-Type': 'application/json' },
					body: JSON.stringify(payload)
				}
			);
			const content = await rawResponse.json();
			setIsLoading(false);
			setClientSecret(content.clientSecret);
		})();
	// eslint-disable-next-line
	}, []);

	return (
		<React.Fragment>

			{ clientSecret && !isLoading && 
				<CheckoutWrap 	cart={props.cart}
								cartTotal={props.cartTotal}
								cartOptions={props.cartOptions}
								stripePromise={props.stripePromise}
								resetShop={props.resetShop}
								clientSecret={clientSecret} />
			}

			{ isLoading &&  <Spinner /> }

		</React.Fragment>
	)

	/*
	return message 
		? ( <Message message={message} /> ) 
		: ( <ProductDisplay handleClick={handleClick} 
							cart={props.cart}
							cartTotal={props.cartTotal}
							cartOptions={props.cartOptions}
							stripePromise={props.stripePromise} /> );
	*/
}