import React,{useState, useEffect} from 'react';
import Spinner from './Spinner'
import config from './Config'
import AddToCart from './AddToCart'


export const urlFor = (file, params) => {
	let imgObjParams = config.imageSizes[params.type];
	let imgParams = Object.entries(imgObjParams).map(([key, val]) => `${key}=${val}`).join('&')
	return `${config.app.cdn}${file}?${imgParams}`;
}

function MediaImage( props ){

	const [isLoaded, setIsLoaded] = useState("loading");

	useEffect( () => {
		//
	// eslint-disable-next-line
	},[])

	return (
		
		<React.Fragment>

			<figure data-status={isLoaded} 
					data-type={`image`}>

				{ isLoaded !== "loaded" && <Spinner /> }

				<img 	onLoad={ () => { setIsLoaded("loaded") } }
						onError={ () => { setIsLoaded("error") } }
						loading="lazy" 
						alt=""
						className="post__image"
						src={urlFor( props.path, {type: 'mobile'})}
						srcSet={`${urlFor(props.path, {type: 'mobile'})} 1100w, ${urlFor(props.path, {type: 'single'})} 1400w`}
						sizes={"90vw"} 
						lb={urlFor(props.path, {type: 'lightbox'})}
						onClick={ ((e)=>{ 
							if(props['data-lightbox'] === true ) {
								console.log('lightbox for ', e.target.getAttribute('lb') )
							} 
						}) }
						{ ...props }
						draggable={false} />

				<figcaption>
					{ props.caption !== null && props.caption }
				</figcaption> 

				{props.children}

				{ config.cart.useCart && <AddToCart image={props.item} /> }

			</figure>

		</React.Fragment>

	)

}

export default MediaImage;