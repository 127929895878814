import React, {useRef,useEffect} from 'react'
import ScrollBooster from 'scrollbooster';

export default function Drag(props){

	const refGallery = useRef(null);
	const refGalleryWrap = useRef(null);

	useEffect( () => {

		const isHorizontal = 	( window.innerWidth > 776 );
		let isNativeScroll = 	true; //( window.innerWidth > 824 );

		new ScrollBooster({
			viewport: 		refGallery.current,
			content: 		refGalleryWrap.current,
			scrollMode: 	isNativeScroll ? "native" : "transform",
			direction: 		isHorizontal ? "horizontal" : "vertical",
			bounce: 		false,
			friction: 		0.05,
			bounceForce: 	0.1
		});
		
	// eslint-disable-next-line
	},[])

	return (

		<div 	className={`${props.className} slide`} 
				ref={refGallery} >

			<div className="slide__wrap" ref={refGalleryWrap} >
				{ props.content }
			</div>

		</div>

	)
}