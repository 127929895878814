import React,{useContext,useState,useEffect,useRef} from 'react'
import DataContext from '../components/dataContext'
import FilterItems from '../components/FilterItems'
import GridItem from '../components/GridItem'
import Slide from '../components/Slide'
import Hello from '../components/Hello'
import Spinner from '../components/Spinner'
import config from '../components/Config'
import {scrollToLeft, scrollSetup} from '../components/Helpers'
//import LocomotiveScroll from 'locomotive-scroll';
import { useHead, useLink } from 'hoofd';

function AllPosts(props){

	const contextData = useContext( DataContext );

	// eslint-disable-next-line
	const [posts, setPosts] = useState([]);
	// eslint-disable-next-line
	const [categoryObj, setCategoryObj] = useState(contextData.category);

	const listingRef = useRef();

	// on load
	useEffect( () => {
		//scrollSetup();
	}, []);

	// on data change
	useEffect( ()=>{
		contextData.loaderSetStatus(true);
		setPosts([]);
		setTimeout( () => {
			setPosts(contextData.posts)
			contextData.loaderSetStatus(false);
			scrollToLeft();
			scrollSetup();
		},100);
	// eslint-disable-next-line
	}, [contextData.posts, contextData.category]);

	const updateCategory = (category) => {
		contextData.setCategory( category );
		setCategoryObj(category);
	}

	const resetFilters = () => {
		updateCategory( null );
	}

	const containerClass = ( contextData.loaderStatus ) ? `loading`: `loaded`;


	// set meta
	useHead({	language: "en", 
				title: (contextData && contextData.settings) ? contextData.settings.name : '',
				metas: [
					{ name: 'description', content: (contextData && contextData.settings) ? contextData.settings.slogan : '' },
					{ name: 'og:title', content: (contextData && contextData.settings) ? contextData.settings.name : '' },
					{ name: 'og:image', content: (contextData && contextData.settings) ? contextData.settings.bg_image_url : ''}
				]
	}); 
	
	useLink({rel: "dns-prefetch", "href": config.app.url});
	useLink({rel: "icon", "href": "icon.png"});


	return (

		<React.Fragment>

			{/* show hello if no category is set */}
			{ (contextData && contextData.settings !== null && categoryObj === null ) && 
				<Hello 	categories={contextData.categories}
						settings={contextData.settings} 
						updateFilter={updateCategory} /> 
			}

			<div className={`posts posts--${containerClass}`}>

				{ contextData.postsLoaded && 
					<div className="posts__filters">

						<div className={`posts__filters--menu`}>
							{/* CATEGORY : filter items */}
							{ contextData && contextData.categories.length > 0 && categoryObj !== null && 
								<FilterItems 	items={contextData.categories} 
												type="category" 
												updateFilter={updateCategory} 
								/>
							}
						</div>

						<div className={`posts__filters--info`}>
							{/* CATEGORY : clear filters, if set */}
							{ posts.length > 0 && categoryObj !== null && 

								<span 	className={`posts__filter posts__filter--counter`}
										onClick={resetFilters} >
									{posts.length} { (posts.length === 1 ) ? `album`: `albums`}

									{/* (categoryObj !== null) && config.lang.listAll */}

								</span> 
							}

						</div>

					</div>
				}

				
				{ contextData.postsLoaded && posts && categoryObj !== null && 

					// show list only if category is set
					<Slide 	className={`posts__listing`}
						content={ <React.Fragment>
									{ posts.map( (item,idx) => {
										if( parseInt( item.isActive ) ){
											return <GridItem key={idx} item={item} />
										}
										return false;
									}) }  
									</React.Fragment>
								} 
					/>

				}

				{ contextData.postsLoaded && posts.length === 0 &&
					<div className="posts__listing" ref={listingRef}>
						<p className="posts__item posts__item--no-results" >
							<span onClick={resetFilters} >{ config.lang.noResults }</span>
						</p> 
					</div>
				}

				{ !contextData.postsLoaded && <Spinner /> }

				

			</div>
		</React.Fragment>
	)

}

export default AllPosts;