export const nth = (d) => {
	if (d > 3 && d < 21) return 'th';
	switch (d % 10) {
	  case 1:  return "st";
	  case 2:  return "nd";
	  case 3:  return "rd";
	  default: return "th";
	}
}

export const animateHeading = (name, ref) => {
	name = name + "%%";
	let nameSplit = name.split("");
	let nameStr = "";

	nameSplit.map( (nameChar,idx) => {
		let checkedChar = ( nameChar === " " ) ? '&nbsp;' : nameChar;
		checkedChar = ( checkedChar === "%" ) ? ' ' : checkedChar;
		setTimeout( ()=>{ 
			nameStr += `<span>${checkedChar}</span>`;
			ref.current.innerHTML = nameStr;
		},23 * idx);
		return false;
	})
}

export const numberFormat = (number) => {
	return ( number.toString().split('.')[1] === undefined ) ? `${number}.00` : number;
}

export const priceFormat = (price) => {
	return parseFloat(price).toFixed(2)
}

export const orderPriceFormat = (price) => {
	return price * 100;
}

export const parseApiVariants = ( variants ) => {
	const jsonVariants = JSON.parse(variants);
	const parsedApiVariants = [];
	
	Object.values( jsonVariants ).forEach( variant => {
		if( parseFloat(variant.price) > 0 && parseInt(variant.active) === 1 ){
			parsedApiVariants.push({
				price: parseFloat(variant.price),
				name: variant.name,
				info: variant.info
			});
		}
	});

	return parsedApiVariants;
}

export const scrollToLeft = () => {
	let elem = document.querySelector('.slide__wrap');
	if( elem !== null && elem !== undefined ){
		elem.scrollTo({left: 0, behaviour: 'smooth'});
	}
}

export const scrollSetup = () => {

	function verticalToHorizontal(elem,e){
		elem.scrollLeft += e.deltaY;
	}
	let elem = document.querySelector('.slide__wrap');
	if( elem !== null && elem !== undefined ){
		elem.addEventListener("wheel", e => { verticalToHorizontal(elem,e); });
		elem.addEventListener("onwheel", e => { verticalToHorizontal(elem,e); });
	}

}

export const recognizeMedia = ( mediaItem ) => {
	let mediaName = mediaItem.data.toLowerCase();
	let mediaType = "null";
	if( mediaName.lastIndexOf('.jpg') >= 0 || mediaName.lastIndexOf('.jpeg') >= 0 || mediaName.lastIndexOf('.png') >= 0 ){
		mediaType = "image";
	}
	else if( mediaName.lastIndexOf('.wav') >= 0 || mediaName.lastIndexOf('.mp3') >= 0 ){
		mediaType = "audio";
	}
	else if( mediaName.lastIndexOf('.mp4') >= 0 || mediaName.lastIndexOf('.mov') >= 0 ){
		mediaType = "video";
	}
	return mediaType;
}

export const setLS = (lsKey, lsContent) => {
	localStorage.setItem( lsKey, lsContent );
}

export const getLS = (lsKey) => {
	return localStorage.getItem( lsKey );
}

export default nth