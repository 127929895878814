export const config = {

	app: {

		// prod
		
		url: 		'https://www.silvijadogan.com/',
		api: 		`https://www.silvijadogan.com/cms/api/`,
		uploads: 	`https://www.silvijadogan.com/cms/uploads/`,
		cdn: 		`https://agewvlkkgp.cloudimg.io/v7/www.silvijadogan.com/cms/uploads/`
		
		
		// dev
		/*
		url: 		'http://192.168.43.17:3000/',
		api: 		`http://192.168.43.17/cms/api/`,
		uploads: `http://192.168.43.17/cms/uploads/`,
		cdn: 		`http://192.168.43.17/cms/uploads/`
		*/
	},

	// filter labels
	filterTypes:{
		category: 	"Subject"
	},

	// images
	imageSizes:{
		homepage: {
			w: 1400
		},
		grid: {
			w: 1400
		},
		single: {
			w: 1400,
		},
		mobile: {
			w: 700
		},
		thumb: {
			w: 300
		},
		lightbox: {
			w: 2000
		}
	},

	// stripe data
	stripe: {
		publishKey: 'pk_test_51HjtGVA9YW7M778SNWL1sXcymJ1VDfOH0MMVD9HRxNeABHyRVnU5r8JkMADYlkgm4SlySB9AFRr6ZbMXppgAjV0q00cWv1UOd3',
	},

	// shop
	cart: {
		useCart: 		false,
		useQty: 		true,
		useVariants: 	true,

		// displayed in gallery
		buyLabel: 		'Add ',
		boughtLabel: 	'Added ',
		disabledLabel: 	'Product is in your order',
		barLabel: 		'Cart: ',

		// currency
		currency: 		'$',

		// displayed in cart
		cartLabel: 		'01. Cart',
		checkoutLabel: 	'02. Checkout',
		paymentLabel: 	'03. Payment',
		totalLabel: 	'Total:',
		subtotalLabel: 	'Subtotal:',
		closeLabel: 	'Close',
		clearLabel: 	'Clear',
		removeLabel: 	'x',
	},

	// order
	order: {
		success: {
			title: 			"Thanks for shopping with us!",
			text: 			"Minim ea cupidatat proident tempor incididunt eiusmod ad. Enim aliqua cillum laboris laboris dolor Lorem cillum adipisicing veniam duis pariatur quis est.",
			closeLabel: 	'Accept & Close',
		}
	},

	// lang
	lang: {
		listAll: 			'',
		detailPrefix: 		'',
		detailNamePrefix: 	'',
		detailAll: 			'Home',
		navigationPrefix:	'album ',
		navigationIndex:	' / ',

		noResults: 	'No content found.',
	}

}

export default config;