import React, {useEffect,useState,useContext, useRef} from 'react';
import {useParams} from 'react-router-dom';
import DataContext from '../components/dataContext';
import Media from '../components/Media'
import Slide from '../components/Slide'
import {scrollToLeft, scrollSetup, nth, animateHeading, recognizeMedia, getLS, setLS} from '../components/Helpers'
import config from '../components/Config'
import TextBox from '../components/TextBox'
//import Svg from '../components/Svg'
import { useHead, useLink } from 'hoofd';

export default function SinglePost(props){

	const contextData = useContext( DataContext );
	
	const [PostData, setPostData] = useState(null);
	const [postContentVisible, setPostContentVisible] = useState(true);

	const refTitle = useRef(null);
	const refTitleVertical = useRef(null);

	const { slug } = useParams();

	useEffect( ()=>{

		if( PostData ){
			refTitleVertical.current.classList.add('fx');
			PostData.media = [];
			PostData.category_list = [];
			PostData.description = "";
			refTitle.current.innerHTML = "";
			setPostData(PostData);
		}

		if( slug ){
			contextData.loaderSetStatus(true);
			fetch( `${config.app.api}items/?slug=${slug}&media=1` )
			.then( result => result.json())
			.then( result => { 
				// update content
				setPostData(result[0]);
				contextData.setPost(result[0]);
				// delay actions
				setTimeout( ()=>{ 
					contextData.loaderSetStatus(false);
					animateHeading( result[0].title, refTitle );
					refTitleVertical.current.classList.remove('fx');
					scrollToLeft();
					scrollSetup();

					let lsContentVisibility = getLS( 'SD_contentVisible' );
					let contentVisibility = ( typeof lsContentVisibility === "string" ) ? JSON.parse(lsContentVisibility) : true;
					setPostContentVisible( contentVisibility );
				},1);
			})
			.catch( console.error );

		}
	// eslint-disable-next-line
	}, [slug, contextData.user, contextData.category]);

	
	// set meta
	useHead({	language: "en", 
				title: ( PostData ) ? `Silvija Dogan - ${PostData.title}` : `Silvija Dogan`,
				metas: [
					{ name: 'description', content: ( PostData ) ? PostData.subtitle : `` },
					{ name: 'og:title', content: ( PostData ) ? `Silvija Dogan - ${PostData.title}` : `Silvija Dogan`}
				]
	});
	useLink({rel: "dns-prefetch", "href": config.app.url});
	useLink({rel: "icon", "href": "icon.png"});

	const containerClass = ( contextData.loaderStatus ) ? `loading`: `loaded`;
	const contentClass = ( postContentVisible ) ? ``: `post__content--hidden`;
	const contentTogglerClass = ( postContentVisible ) ? ``: `active`;

	const setContentVisibility = () => {
		let newVisibility = ! postContentVisible;
		setPostContentVisible( newVisibility );
		setLS( 'SD_contentVisible', newVisibility );
	}

	return (

		<div className={`post post--${containerClass}`}>

			{ PostData &&

				<React.Fragment>

					<img 	className={`post__content-close ${contentTogglerClass}`} 
							onClick={setContentVisibility}
							alt="toggle"
							src={`/assets/icons/text_toggler.png`} />

					<h3 className={`post__title-vertical ${contentTogglerClass}`} 
						ref={refTitleVertical} 
						onClick={setContentVisibility}>
							{PostData.title}
					</h3>

					<div className={`post__content ${contentClass}`}>

						<h2 className="post__title" ref={refTitle} onClick={setContentVisibility}>{}</h2>

						<TextBox data={{subtitle: PostData.subtitle, 
										description: PostData.description}} />
						
					</div>
					
					{ PostData.media.length > 0 && 
						<React.Fragment>

							{/* images and video */}
							<Slide 	className="post__images" 
									content={ PostData.media.map( (media,idx) => {
											if( recognizeMedia(media) !== 'audio' ){
												const mediaIndex = `${idx+1}${nth(idx+1)}`;
												return <Media 	key={`media_${idx}`}
																item={media}
																path={`item/${PostData.id}/${media.data}`}
																caption={`${mediaIndex} / ${PostData.media.length}`}
																data-lightbox={true}
												/>
											}
											return null;
									})}
							/>

							{/* audio */}
							<div className="post__audios">
							{
								PostData.media.map( (media,idx) => {
									if( recognizeMedia(media) === 'audio' ){
										const mediaIndex = `${idx+1}${nth(idx+1)}`;
										return <Media 	key={`media_${idx}`}
														item={media}
														path={`item/${PostData.id}/${media.data}`}
														caption={`${mediaIndex} / ${PostData.media.length}`}
														data-lightbox={true}
										/>
									}
									return null
								})}
							</div>

						</React.Fragment>
					}

				</React.Fragment>
			}
			
		</div>
		
	)

}