import React, {useContext, useEffect, useState} from 'react';
import {Link,useHistory } from 'react-router-dom';
import DataContext from '../components/dataContext';
import {nth} from '../components/Helpers'

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import config from '../components/Config'

export default function Menu( props ){

	const contextData = useContext( DataContext );
	const [navigation, setNavigation] = useState({prev: null, next: null, index: 0, count: 0});
	const [contextCategory, setContextCategory] = useState(null);

	let history = useHistory();

	useEffect(() => {
		if( contextData !== null && contextData.post !== null ){
			// find current
			contextData.posts.map( (post,idx) => {
				if( post.id === contextData.post.id ){
					let newNavigation = {	
						count: contextData.posts.length,
						index: idx + 1,
						prev: ( contextData.posts[idx-1] !== undefined ) ? contextData.posts[idx-1] : null,
						next: ( contextData.posts[idx+1] !== undefined ) ? contextData.posts[idx+1] : null
					}
					setNavigation( {...navigation, ...newNavigation} );
				}
				return false;
			})
		}
	// eslint-disable-next-line
	}, [contextData])

	useEffect(() => {
		setContextCategory( contextData.category );
	// eslint-disable-next-line
	}, [contextData.category])

	const gotoIndex = () => {
		history.push('/');
	}

	return (
		
		<header className="menu">

			{/* home button */}
			{ props.location.pathname !== '/' && 

				<React.Fragment>

					<div className="menu__index" onClick={gotoIndex} >

						<span>
							{ config.lang.detailPrefix }
							{ contextCategory === null && config.lang.detailAll }
							{ contextCategory !== null && typeof contextCategory === 'object' && ` ${contextCategory.title} ` }
						</span>
					</div>

					<div className="menu__navigation">

						<span className="divider"></span>

						{/* ( navigation.count === 1 ) && `Single album` */}

						{ navigation.prev !== null && 
							<Link 	key={`prev`} 
									to={`/${navigation.prev.slug}`}
									className="menu__navigation-prev" >
								<MdKeyboardArrowLeft /> {navigation.prev.title}
							</Link>
						}
						{ navigation.prev === null && navigation.count > 1 && 
							<span 	className="menu__navigation-prev menu__navigation-disabled"> 
								<MdKeyboardArrowLeft />
							</span> 
						}

						{/*
						{ ( navigation.count > 1 ) && 
							<span className="divider divider--half"></span>
						}

						<span 	className="menu__title"> 
							{ contextData && contextData.post && 
								`${config.lang.detailNamePrefix} ${contextData.post.title}` }
						</span>

						{ navigation.next !== null && navigation.count > 1 && 
							<span className="divider divider--half"></span>
						}
						*/}

						<span className="divider divider--half"></span>

						{ navigation.next !== null && 
							<Link 	key={`next`} 
									to={`/${navigation.next.slug}`} 
									className="menu__navigation-next" >
								{navigation.next.title} <MdKeyboardArrowRight />
							</Link>
						}
						{ navigation.next === null && navigation.count > 1 && 
							<span 	className="menu__navigation-next menu__navigation-disabled" > 
								<MdKeyboardArrowRight />
							</span>
						} 

					</div>

					<div className="menu__aside">
					
						<span  className="menu__album">
							{ ( navigation.count > 1 ) && 
								<React.Fragment>
									{`${navigation.index}` }
									<sup>{`${nth(navigation.index)}`}</sup>
									{`${config.lang.navigationIndex} ${navigation.count} `}
								</React.Fragment> 
							}
						</span>

						{/*
						{ ( navigation.count > 1 ) && 
							<span className="divider divider--half"></span>
						}

						<span className="menu__counter ">
							{ contextData && contextData.post &&  
								<React.Fragment>
									{ ` ${contextData.post.media.length} ` }
									{ ( contextData.post.media.length === 1 ) ? `item` : `items` }
								</React.Fragment>
							}
						</span>
						*/}

						<span className="menu__close" >
							<span className="divider divider--half"></span>
							<span onClick={gotoIndex}>Close</span>
						</span>
						

					</div>

					

				</React.Fragment>
			}

		</header>

	)

}