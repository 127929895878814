import React,{useContext} from 'react'
import DataContext from './dataContext'

export default function FilterItems(props){

	const contextData = useContext( DataContext );

	return (
		<React.Fragment>
			
			{/*<span className={`posts__filter posts__filter--title`}>{config.filterTypes[props.type]}</span>*/}

			{  	props.items.map( (item,idx) => {
				
				const isActiveCity = (contextData && contextData[props.type] && typeof contextData[props.type]==='object' && contextData[props.type].id === item.category.id);
				const classItem = ( isActiveCity ) ? `active` : `inactive`;
				const updateItemValue = ( isActiveCity ) ? null : item.category;
				
				return <span 	key={`${props.type}_${idx}`}
								className={`posts__filter posts__filter--${classItem}`}
								onClick={ (e) => props.updateFilter(updateItemValue) }
						>
							{item.category.title}
						</span>
				})
			}
		</React.Fragment>
	)
	
}