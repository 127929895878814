import React,{useContext, useEffect, useState} from 'react'
import DataContext from './dataContext'
import config from '../components/Config'
import {parseApiVariants} from '../components/Helpers'
import _ from 'lodash';

export default function AddToCart(props){

	const contextData = useContext( DataContext );

	const [variants, setVariants] = useState([]);

	useEffect( () => {
		if( props.image && props.image.pricings ){
			setVariants( parseApiVariants( props.image.pricings ) );
		}
	// eslint-disable-next-line
	},[contextData.cart] );

	const addToCart = () => {
		contextData.cartDispatch({	type: 'add', 
									payload: {	item: props.image, 
												variant: variants.shift() }
		});
		openCart();
	}

	const openCart = () => {
		document.querySelector('.shop').classList.add('shop--active');
		document.querySelector('.shop__header-cart').click();
	}

	const isAddedToCart = () => {
		return ( -1 <
			_.findIndex( contextData.cart, (e) => {
				return e.item.id === props.image.id;
			}, 0)
		);
	}

	return (

		<React.Fragment>

			{/* if image has a price defined ... */}
			{ variants.length >= 1 &&  

				<React.Fragment>

					{/* cart-add available */}
					{ contextData.cartDisabled === false && 
						<React.Fragment>
							{ isAddedToCart() === true && 
								<div 	className="add-to-cart add-to-cart--added">
									{config.cart.boughtLabel}
								</div>
							}
							{ isAddedToCart() === false && 
								<div 	className="add-to-cart"
										onClick={addToCart}>
									{config.cart.buyLabel}
								</div>
							}
						</React.Fragment>
					}

					{/* cart-add disabled */}
					{ contextData.cartDisabled === true && isAddedToCart() === true && 
						<React.Fragment>
							<div 	className="add-to-cart add-to-cart--disabled" onClick={openCart}>
								{config.cart.disabledLabel}
							</div>
						</React.Fragment>
					}

				</React.Fragment>

			}

		</React.Fragment>
	)
	
}