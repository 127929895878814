import React, {useContext} from 'react'
import DataContext from '../components/dataContext'

export default function FilterItem(props){

	const contextData = useContext( DataContext );

	return (
		contextData.loaderStatus && <div className="loading"></div>
	)
}
