import React,{useEffect,useState} from 'react'
import HelloImage from '../components/HelloImage'




export default function HelloGallery (props){

	const [mediaIndex, setMediaIndex] = useState(0);
	const [mediaSet, setMediaSet] = useState({	current: null, 
												next: null });

	useEffect( () => {
		if( props.items.length > 1 ){
			const timeout = setInterval(() => {
				setMediaIndex( mediaIndex => { 
					return (mediaIndex < props.items.length-1 ) ? mediaIndex + 1 : 0;
				});
			}, 7300);
			return () => {
				clearInterval(timeout);
			};
		}
	// eslint-disable-next-line
	},[] )

	useEffect( () => {
		if( props.items.length > 1 ){
			let nextIndex = ( mediaIndex < props.items.length-1 ) ? mediaIndex + 1 : 0;
			setMediaSet({
				current: props.items[ mediaIndex ],
				next: props.items[ nextIndex ] 
			});
		}else{
			setMediaSet({
				current: props.items[ mediaIndex ],
				next: null 
			});
		}
	// eslint-disable-next-line
	},[mediaIndex] )

	return (

		<div className="hello-gallery">

			{ mediaSet.current !== null &&
				<HelloImage key={ mediaSet.current.id }
					{...{	
						path: 		`${mediaSet.current.model_type}/${mediaSet.current.model_id}/${mediaSet.current.data}`,
						className: 	"hello-gallery__image hello-gallery__current",
						alt:		`current` 
					}} 
				/> 
			}

			{ mediaSet.next !== null &&
				<HelloImage key={ mediaSet.next.id }
					{...{	
						path: 		`${mediaSet.next.model_type}/${mediaSet.next.model_id}/${mediaSet.next.data}`,
						className: 	"hello-gallery__image hello-gallery__next",
						alt:		`next` 
					}} 
				/> 
			}

		</div>

	)
}